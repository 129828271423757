<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         show-expand
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-select v-model="table.filter" :items="features" placeholder="Feature Name"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" multiple hide-details clearable
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;"
                  >
                  <template v-slot:selection="{ item, index }">
                     <span v-if="index < 1">{{ index === 0 ? item.text : `, ${item.text}` }}</span>
                     <span v-if="index === 1" class="grey--text ml-2">
                        ( +{{ table.filter.length - 1 }} more )
                     </span>
                  </template>
               </v-select>
               <v-spacer />
               <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'C')" x-large icon tile color="secondary" class="mx-1" @click="option.id = 0">
                  <v-icon size="48">
                     mdi-plus-box
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.feature`]="{ item }">
            {{ (features.find(j => j.value === item.feature) || { text: '' }).text }}
         </template>
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pb-1">
               <template v-for="(tag, idx) in item.Flows">
                  <v-icon v-if="idx > 0" :key="`next${idx}`" small class="pt-1 mx-1">
                     mdi-arrow-right
                  </v-icon>
                  <v-chip :key="idx" small label outlined class="mr-1 mt-1 px-2"
                     :color="tag.name === 'Approved' ? 'teal darken-1' : tag.name === 'Rejected' ? 'red darken-2' : ''"
                     >
                     {{ `${tag.code}: ${tag.name}` }}
                  </v-chip>
               </template>
            </td>
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import { imageFromUrl } from '@/utils/nirtara'
import { } from '@/utils/fields.js'
export default {
   name: 'NSequenceTable',

   props: {
      option: { type: Object },
      records: { type: Array },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Feature', value: 'feature', width: 140 },
         { text: 'Sequence', value: 'name' },
         { text: '', value: 'data-table-expand', align: ' pl-2 pr-0' },
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
      features: [],
   }),

   computed: {
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            return (!search || filteredName) &&
                   (!this.table.filter.length || this.table.filter.includes(j.feature))
         })
      },
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   created () {
      this.features = [
         { text: 'Approval', value: 'APV' },
         { text: 'Invitation', value: 'INV' },
      ]
   },
   methods: {
      modifyRecord (item) {
         Object.assign(this.option, { kind: 'Sequence', name: '', identity: '*', feature: '', zone: '' }, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
   -webkit-box-shadow: none;
   box-shadow: none;
}
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
   -webkit-box-shadow: none;
   box-shadow: none;
}
</style>
